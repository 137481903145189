export const api_url = process.env.VUE_APP_API_URL
export const url_prismaccess = process.env.VUE_APP_URL_PRISMACCESS
export const base_url = process.env.BASE_URL
export const url_preprod_prismaccess = process.env.VUE_APP_URL_PREPROD_PRISMACCESS

export default {
    api_url,
    url_prismaccess,
    base_url,
    url_preprod_prismaccess,
}